<template lang="html">
<v-container fluid>
  <lock-page :status="OpenClose" :keterangan="'Menu belum dibuka. Tunggu informasi selanjutnya, terima kasih.'"></lock-page>

  <v-row v-if="showDataASNCerdas && OpenClose == 'open'">
    <v-col cols="12">
      <base-material-card
      color="green lighten-1"
      icon="mdi-check-all"
      :title="title"
      >
      <v-simple-table>
        <thead>
          <tr>
            <th>No</th>
            <th>NIP</th>
            <th>Nama</th>
            <th class="yellow lighten-3">Aktivitas Harian <br>(20%)<br> a</th>
            <th class="yellow lighten-3">Perilaku<br> (30%)<br><br> b</th>
            <th class="yellow lighten-3">Kehadiran (25%)<br><br> c</th>
            <th class="yellow lighten-3">Aktivitas Negatif (25%)<br> d</th>
            <!-- <th>SKP (%)</th> -->
            <th class="blue lighten-3">Nilai Kinerja (100%)<br>(a+b+c+d) </th>
            <th class="blue lighten-3">Nilai Survey (100%)<br><br></th>
            <th class="green lighten-2">60% Nilai Kinerja</th>
            <th class="green lighten-2">40% Nilai Survey</th>
            <th class="green lighten-1">Nilai Akhir <br> (60%+40%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(peg,i) in asn_cerdas" :key="i">
            <td>{{ incrementIndex(i) }}</td>
            <td>{{peg.peg_nip || peg.nip}}</td>
            <td>{{peg.nama}}</td>
            <td>{{peg.aktivitas_harian}}</td>
            <td>{{peg.perilaku}}</td>
            <td>{{peg.kehadiran}}</td>
            <td>{{peg.aktivitas_negatif}}</td>
            <!-- <td>{{ (Math.round(peg.nilai_skp * 100) / 100) }}</td> -->
            <td>{{peg.nilai_kinerja}}</td>
            <td>{{(peg.nilai_perilaku)}}</td>
            <td>{{peg.persen_60}}</td>
            <td>{{peg.persen_40}}</td>
            <td class="green lighten-1">{{peg.nilai_total}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      </base-material-card>
    </v-col>
  </v-row>
  <div v-else>
    <v-row justify="center" v-if="OpenClose == 'open'">
      <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
        <v-alert
        color="blue-grey"
        dark
        dense
        icon="mdi-account-group"
        prominent
      >
        Belum ada data kandidat yang harus diulas
      </v-alert>
      </v-col>
    </v-row>
    <v-row class="mt-5 mb-5" v-if="OpenClose == 'open'">
      <v-col align="center">
        <v-btn color="primary" :href=" baseURL + 'survey/pilih-kandidat'">Pilih Kandidat dan Isi Rating Penilaian</v-btn>
      </v-col>
    </v-row>
  </div>

  <v-row v-if="loadingBtn.dataPegawai">
    <v-col v-for="i in [1,2,3,,4,5]" :key="i">
      <v-skeleton-loader
      class="mx-auto"
      min-width="300"
      type="card"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row class="mt-5" v-if="OpenClose == 'open'">
    <v-col v-for="(peg,i) in asn_cerdas" :key="i" align="center">
      <base-material-card
        :class="i > 2 ? 'v-card-profile grey lighten-3' : 'v-card-profile elevation-9'"
        :avatar="peg.foto"
        style="width:300px"
      >
        <v-card-text class="text-center" height="100px">
          <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:auto;">
            {{peg.nama}}
          </h4>
          <h4 class="grey--text mb-2">
            NIP. {{peg.nip}}
          </h4>
          <h4 class="green--text" style="font-size:12px; height:auto;">
            {{peg.jabatan}}
            <!-- <div v-if="peg.jabatan_jenis == 2">
              {{ peg.jabatan_nama_alias ? peg.jabatan_nama_alias : peg.jabatan_nama }}
            </div>
            <div v-if="peg.jabatan_jenis == 3">
              {{ peg.jf_nama }}
            </div>
            <div v-if="peg.jabatan_jenis == 4">
              {{ peg.jfu_nama }}
            </div> -->
          </h4>
          <div class="mt-2">
            Nilai Kinerja + Nilai Survey:
            <v-chip v-if="i == 0" small label class="ma-1 green darken-2" dark>{{(Math.round(peg.nilai_total * 100) / 100)}}</v-chip>
            <v-chip v-else small label class="ma-1 orange darken-2" dark>{{(Math.round(peg.nilai_total * 100) / 100)}}</v-chip>
          </div>
          <div v-if="i < 3">
            Nilai Kepala PD:
            <v-chip small label class="ma-1 primary" dark>{{ Number.isInteger(peg.nilai_jpt) ? (Math.round(peg.nilai_jpt * 100) / 100) : peg.nilai_jpt}}</v-chip>
            <!-- <v-chip v-if="i == 0" small label class="ma-1 green darken-2" dark>{{ Number.isInteger(peg.nilai_jpt) ? (Math.round(peg.nilai_jpt * 100) / 100) : peg.nilai_jpt}}</v-chip>
            <v-chip v-else small label class="ma-1 orange darken-2" dark>{{ Number.isInteger(peg.nilai_jpt) ? (Math.round(peg.nilai_jpt * 100) / 100) : peg.nilai_jpt}}</v-chip> -->
          </div>
          <div v-if="i < 3 && role == 'JPT'">
            <v-btn color="primary" small class="mr-0" @click="penilaianJPT(peg,i)">
              <span><v-icon>mdi-pencil-box</v-icon> Berikan Penilaian</span>
            </v-btn>
          </div>
        </v-card-text>
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
          color="primary"
          rounded
          class="mr-0"
          @click="openDialog(peg.nip,i)"
          :loading="loadingEl == i"
          >
            <span v-if="peg.remedial"><v-icon>mdi-pencil-box</v-icon> Review Ulang</span>
            <span v-else-if="peg.submit_survey_ref"><v-icon>mdi-eye</v-icon> Lihat</span>
            <span v-else><v-icon>mdi-pencil-box</v-icon> Ulas</span>
          </v-btn>
        </v-card-actions> -->
      </base-material-card>
    </v-col>
  </v-row>

  <!-- <base-material-card
    color="green lighten-1"
    icon="mdi-account-multiple"
    title="Daftar Review Kinerja Pegawai ASN Cerdas 2022"
  >
  <v-alert
  class="mt-3"
  prominent
  type="info"
  v-if="responseData.sudah_ulas == 0"
  >Belum mengulas pegawai. Jumlah pegawai yang harus diulas adalah {{responseData.harus_ulas}} orang</v-alert>
  <v-alert
  class="mt-3"
  prominent
  type="success"
  v-else-if="responseData.sudah_ulas == responseData.harus_ulas && responseData.harus_ulas > 0"
  >Sudah mengulas pegawai. Total ulasan berjumlah {{responseData.sudah_ulas}}</v-alert>
  <v-alert
  class="mt-3"
  prominent
  type="info"
  v-else-if="responseData.sudah_ulas > 0"
  >Sudah melakukan {{responseData.sudah_ulas}} ulasan kompetensi dari {{responseData.harus_ulas}} orang</v-alert>

  <v-row v-if="!pegawai.length">
    <div v-if="pegawai.length > 0">
    <v-col v-for="i in [1,2,3,,4,5]" :key="i">
      <v-skeleton-loader
      class="mx-auto"
      min-width="300"
      type="card"
      ></v-skeleton-loader>
    </v-col>
    </div>
  </v-row>
  <v-row class="mt-5">
    <v-col v-for="(peg,i) in pegawai" :key="i" align="center">
      <base-material-card
        class="v-card-profile"
        :avatar="peg.foto"
        style="width:300px"
      >
        <v-card-text class="text-center" height="100px">
          <h4 class="display-2 mb-1 orange--text text--darken-2" style="height:55px;">
            {{peg.nama}}
          </h4>
          <h4 class="font-weight-light grey--text mb-2">
            NIP. {{peg.nip}}
          </h4>
          <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
            {{peg.jabatan}}
          </h4>
          <div class="mt-5">
            <v-chip v-if="peg.status" small label :class="reviewStatus(peg)" dark class="ma-1">{{peg.status}}</v-chip>
            <v-chip small label class="ma-1 orange darken-2" dark>{{peg.jumlah}}/{{peg.harus}} Pertanyaan</v-chip>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
          color="primary"
          rounded
          class="mr-0"
          @click="openDialog(peg.nip,i)"
          :loading="loadingEl == i"
          >
            <span v-if="peg.remedial"><v-icon>mdi-pencil-box</v-icon> Review Ulang</span>
            <span v-else-if="peg.submit_survey_ref"><v-icon>mdi-eye</v-icon> Lihat</span>
            <span v-else><v-icon>mdi-pencil-box</v-icon> Ulas</span>
          </v-btn>
        </v-card-actions>
      </base-material-card>
    </v-col>
  </v-row>
  </base-material-card> -->

  <!-- <v-dialog v-model="dialog"> -->
    <!-- <SurveyReferensiDetail :dataPegawai="dataPegawai" @onClose="(x)=>{dialog=x}"/> -->
  <!-- </v-dialog> -->

  <!-- <v-dialog v-model="dialogInformasiReviewKompetensi" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Informasi Survey Referensi</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formDialogInformasiReviewKompetensi" style="font-size:18px">
          <ol>
            <li>Survey Referensi adalah proses penyampaian informasi yang berkaitan dengan perilaku yang ditampilkan sehari-hari oleh atasan/rekan sejawat/bawahan yang berinteraksi sehari-hari di dalam pekerjaan atau memiliki hubungan kerja dengan anda sebagai reviewer.</li>
            <br>
            <li>Survey Referensi <strong>tidak mempengaruhi besaran TKD</strong> yang akan diterima seorang reviewer (anda) atau reviewer (orang yang anda review kompetensi nya).</li>
            <br>
            <li>Reviewer kompetensi dilakukan secara acak oleh sistem dan hasil review yang masuk menjadi anonim.</li>
            <br>
            <li>Hasil review kompetensi akan digunakan sebagai :</li>
            <ol style="list-style-type: lower-alpha;">
              <li>Salah satu data rujukan untuk pengembangan kompetensi reviewer;</li>
              <li>Menjadi feedback untuk reviewer mengembangkan kapasitas diri, pribadi, dan kualitas layanan yang dilakukan.</li>
            </ol>
            <br>
            <li>Akan ada pernyataan yang membutuhkan evidence/keterangan yang menjelaskan bahwa perilaku tersebut sesuai dengan penilaian yang dilakukan, jelaskan/tuliskan perilaku yang pernah ditunjukkan oleh reviewer yang pernah terlihat/teramati/terjadi.</li>
            <br>
            <li>Pilihlah pernyataan perilaku yang benar-benar sesuai dengan apa yang ditunjukan oleh reviewer, sehingga kebijakan yang diambil dari proses review kompetensi ini dapat sesuai dengan kebutuhan.</li>
          </ol>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-checkbox class="mx-5" v-model="form.check" @change="setCheck()" label="Setujui Informasi"></v-checkbox>
        <v-btn id="btn-simpan" color="success" :disabled="form.check == null || form.check == false ? false : disabledSimpan" @click="setujuiInformasiReviewKompetensi()">Simpan dan Lanjutkan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> -->
  <v-dialog v-model="dialogPenilaianJPT" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">Penilaian Kepala Perangkat Daerah</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formPenilaianJPT" style="font-size:18px">
          <h4>Berikut Penilaian terhadap {{dataPenilaian.nama}} dari:</h4>
          <br>
          <ol v-for="(data,i) in dataPenilaian.strength_weakness" :key="i">
            <li><b><font size="4">{{data.nama}}</font></b>&ensp;<font size="2">({{data.jabatan}})</font></li>
            <div><font size="3">Memberikan Rating: <v-rating class="mb-0" small v-model="data.rating" background-color="orange lighten-3" color="orange darken-4" size="10" readonly="true"></v-rating></font></div>
            <font size="3">Kelebihan: {{data.komentar_strength}}</font><br>
            <font size="3">Kekurangan: {{data.komentar_weakness}}</font>
          </ol>
          <br>
          <v-text-field dense v-model="dataPenilaian.nilai_jpt" :rules="[rules.required]" outlined :label="'Penilaian Kepala Perangkat Daerah / Masukan Nilai Berupa Angka 0-100 Untuk ' + dataPenilaian.nama"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-checkbox class="mx-5" v-model="form.check" @change="setCheck()" label="Setujui Informasi"></v-checkbox> -->
        <v-btn id="btn-simpan" color="success" @click="simpanPenilaianJPT()">Simpan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
// import SurveyReferensiDetail from './SurveyReferensiDetail'
import SurveyReferensiService from '@/services/SurveyReferensiService'
import store from '@/store'
import axios from 'axios'
import _g from '../../global'
import {defaultRules} from '@/utils/lib'
import LockPage from '@/components/common/LockPage'

export default {
  components:{
    LockPage
    // SurveyReferensiDetail
  },
  data:function(){
    return{
      title:"Hasil Review Kinerja Pegawai ASN Teladan",
      responseData:{},
      pegawai:[],
      dialog: false,
      dataPegawai:{},
      loadingEl:-1,
      dialogInformasiReviewKompetensi: false,
      form:{},
      disabledSimpan: false,
      showDataASNCerdas: false,
      asn_cerdas: [],
      loadingBtn:{
        dataPegawai:false
      },
      dialogPenilaianJPT:false,
      dataPenilaian: [],
      rules:{
        ...defaultRules
      },
      role:null,
      OpenClose: ''
    }
  },
  computed:{
    baseURL: function () {
        let url = window.location.origin
        let x = "/#/"
        return url.concat(x)
    }
  },
  mounted(){
    this.getDataReviewerSurveyReferensiASN()
  },
  created(){
    let user = store.state.user
    if(user.dataPegawai.jabatan.includes("Kepala Badan") || user.dataPegawai.jabatan.includes("Kepala Dinas") ||
      user.dataPegawai.jabatan.includes("Kepala Bagian") || user.dataPegawai.jabatan.includes("Camat") || user.dataPegawai.jabatan.includes("Direktur")){
      this.role = 'JPT'
    }else{
      this.role = 'non-JPT'
    }
    // var kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
    // var skpds = ["4.03.01", "2.10.01", "4.01.01", "4.02.01", "4.05.02", "1.01.01", "1.02.01"];
    // var base_url = window.location.origin
    // var check_skpd = this.$store.getters["user/data"].data_asn["perangkat daerah"]
    // if(check_skpd == 'Badan Kepegawaian, Pendidikan dan Pelatihan' || check_skpd == 'Badan Kepegawaian dan Pengembangan Sumber Daya Manusia'
    // || check_skpd == 'Badan Perencanaan Pembangunan, Penelitian dan Pengembangan' || check_skpd == 'Badan Keuangan dan Aset Daerah'
    // || check_skpd == 'Dinas Komunikasi dan Informatika' || check_skpd == 'Sekretariat Daerah' || check_skpd == 'Badan Pengelolaan Keuangan dan Aset'){
    // if(skpds.includes(kode_skpd)){
      this.getData()
    // }else{
    //   store.commit('snackbar/setSnack',{
    //         message:'Maaf, anda tidak bisa mengakses halaman Survey Referensi. Untuk sementara hanya OPD BKPSDM, Diskominfo, BKAD, Bappelitbang, Sekda, Disdik, dan Dinkes dahulu. Terima kasih.',
    //         color:'warning'})
    //   let confirmAction = confirm("Maaf, anda tidak bisa mengakses halaman Survey Referensi. Untuk sementara hanya OPD BKPSDM, Diskominfo, BKAD, Bappelitbang, Sekda, Disdik, dan Dinkes dahulu. Terima kasih.");
    //   if (confirmAction) {
    //     window.location.href = base_url + '/#/dashboard'
    //   } else {
    //     window.location.href = base_url + '/#/dashboard'
    //   }
    // }
  },
  watch:{
    dialog(val){
      val || this.getData()
    }
  },
  methods:{
    incrementIndex(key) {
        return key + 1;
    },
    setCheck(){
      if(this.form.check == true)this.disabledSimpan = false
      else this.disabledSimpan = true
    },
    getData(){
      this.loadingBtn.dataPegawai = true
      this.form.check = false
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_add_struktur_pokja = base_url_api + 'v1/get-hasil-akhir-asn-teladan'
      axios.post(url_add_struktur_pokja, {
        nip: current.username,
      }).then(response => {
        let res = response.data
        this.OpenClose = res.open_close
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          if(res.asn_teladan.length > 0){
            this.asn_cerdas = res.asn_teladan
          }else{
            this.asn_cerdas = []
          }
          if(this.belum_memilih == false){
            // this.asn_cerdas = response.data.data
          }else{
            // this.asn_cerdas = []
          }
          // this.pegawai = []
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.dataPegawai = false
        if(this.asn_cerdas.length != 0){
          // this.showDataASNCerdas = true
        }else{
          // this.showDataASNCerdas = false
          store.commit('snackbar/setSnack',{message: 'Belum ada data kandidat, silahkan pilih dan isi penilaian ratingnya. Terima kasih!', color:'warning'})
        }
      }).catch(err => {
        var error = err.message
        this.showDataASNCerdas = false
        this.loadingBtn.dataPegawai = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
      // SurveyReferensiService.surveyReferensiGetData().then(response=>{
      //   this.pegawai = response.data.data
      //   this.asn_cerdas = response.data.asn_cerdas
      //   this.showDataASNCerdas = true
      //   for (let i = 0; i < this.pegawai.length; i++) {
      //     const element = this.pegawai[i];
      //     if(element.check){
      //       this.form.check = true
      //       break
      //     }
      //   }
      //   if(!this.form.check){
      //     // this.dialogInformasiReviewKompetensi = true
      //     this.disabledSimpan = false
      //   }
      //   this.responseData = response.data
      // })
    },

    reviewStatus(peg){
      let status = peg.status ? peg.status.toLowerCase() : ''

      return{
        red:status === 'belum mengulas',
        blue:status === 'on going',
        green: status === 'sudah diulas'
        // green:status == 'belum direview'
      }
    },

    openDialog(nip, idx){
      this.loadingEl = idx
      SurveyReferensiService.surveyReferensiUlasNIP(nip).then(response => {
        console.log(response.data)
        this.dataPegawai = []
        this.dataPegawai = response.data
        this.dialog=true
      }).finally(() => {
        this.loadingEl = -1
      })
    },

    setujuiInformasiReviewKompetensi(){
      let check = this.form.check
      if(check == false){
        store.commit('snackbar/setSnack',{
            message:'Silahkan setujui dahulu informasi review kompetensi untuk melanjutkan isi review kompetensi. Terima kasih!',
            color:'warning'})
      }else{
        this.disabledSimpan = true
        let item = document.getElementById("btn-simpan");
        item.textContent = 'Silahkan tunggu'
        SurveyReferensiService.kuesionerSimpanPersetujuanReviewKompetensi(check)
                                                  .then(response=>{
                                                    store.commit('snackbar/setSnack',{message:response.data.message,color:response.data.response.toLowerCase()})
                                                    // this.dialogInformasiReviewKompetensi = false
                                                    this.disabledSimpan = false
                                                    item.textContent = 'Simpan'
                                                  })
      }
    },
    getDataReviewerSurveyReferensiASN(){
      // this.form.check = false
      // var bulan = 8
      this.countPegawai = 0
      this.dataPegawaiBaru = []
      var date = new Date()
      var bulan = date.getMonth()
      //reviewer check if its a PLT or not
      this.loadingBtn.dataPegawai = true
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-data-reviewer-survey-referensi-asn-cerdas'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: (bulan+1),
      }).then(response => {
        let res = response.data
        if (res.success){
          var cek = false
          if(res.data.length > 0){
            res.data.forEach(e => {
              // cek = e.rating == null ? true : false
              if(e.nomor != null && e.rating == null){
                cek = true
              }
            })
          }
          if(res.data.length == 0){
            cek = true
          }
          if(cek == false){
            this.belum_memilih = false
            this.showDataASNCerdas = true
          }else{
            this.belum_memilih = true
            this.asn_cerdas = []
            this.showDataASNCerdas = false
            store.commit('snackbar/setSnack',{message: 'Maaf anda belum memilih dan menilai rating kandidat ASN teladan, silahkan pilih dahulu. Terima kasih!', color:'warning'})
          }
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.dataPegawai = false
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingBtn.dataPegawai = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    penilaianJPT(data, i){
      this.dataPenilaian = data
      this.dataPenilaian.nilai_jpt = null
      this.dialogPenilaianJPT = true

    },
    simpanPenilaianJPT(){
      // var nilai = this.dataPenilaian.nilai_jpt
      if (this.$refs.formPenilaianJPT.validate()){
        let current = store.state.user.current
        let user = store.state.user
        if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
          current.username = _g.overrideWithPLT(user.current.role)
        }else{
          current.username = user.current.username
        }
        var base_url_api = _g.getBaseURLAPIERK(current.year)
        const url = base_url_api + 'v1/asn-teladan-store-penilaian-jpt'
        axios.post(url, {
          nip: this.dataPenilaian.nip,
          nip_jpt: current.username,
          nilai_jpt: this.dataPenilaian.nilai_jpt,
        }).then(response => {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.getData()
          } else {
            store.commit('snackbar/setSnack',{message: res.message, color:'warning'})
          }
        }).finally(()=>{
          this.dialogPenilaianJPT = false
        }).catch(err => {
          var error = err.message ? err.message : err.response.data.message
          this.dialogPenilaianJPT = false
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })

      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
